import { Injectable } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import * as Sentry from "@sentry/browser";
import { take, takeWhile, timer } from "rxjs";
import * as uuid from "uuid";
import { FormInstance } from "../../model/forms/form-instance.model";
import { FormInstanceService } from "../form-instance.service";
import { FormResponseService } from "../form-response.service";
import { FormGroupCompanyService } from "../forms/form-group-company.service";
import { ToastService } from "../toast.service";

export enum FormCompletionStates {
  Intro = "Intro",
  Progress = "Progress",
  ThankYou = "ThankYou",
}

@Injectable({
  providedIn: null,
})
export class FormCompletionService {
  public formGroup: FormGroup;
  public isLoadingFormInstance: boolean = true;
  public isSubmitting: boolean = false;
  public showErrors: boolean = false;
  public formInstance: FormInstance;
  public currentState: FormCompletionStates = FormCompletionStates.Intro;

  private submissionUUID: string = uuid.v4();
  private formInstanceUUID: string | null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formInstanceService: FormInstanceService,
    private formResponseService: FormResponseService,
    private formBuilder: FormBuilder,
    private toastService: ToastService,
    private formGroupCompanyService: FormGroupCompanyService,
  ) {
    this._fetchFormInstance();
  }

  public startFormInstance() {
    this.currentState = FormCompletionStates.Progress;
    const formTimer = (this.formInstance.formTemplate.timerInMinutes || 0) * 60 * 1000;
    const alertBeforeEndFormTimer =
      (this.formInstance.formTemplate.alertBeforeEndTimerInMinutes || 0) * 60 * 1000;

    if (formTimer) {
      timer(formTimer - alertBeforeEndFormTimer, alertBeforeEndFormTimer)
        .pipe(
          take(2),
          takeWhile(() => this.currentState !== FormCompletionStates.ThankYou),
        )
        .subscribe({
          next: (it) => {
            if (it === 0 && alertBeforeEndFormTimer) {
              this.toastService.showWarning(
                `Warning: You have only ${this.formInstance.formTemplate.alertBeforeEndTimerInMinutes} minutes remaining to finish this survey, or you will have to restart from the beginning.`,
                undefined,
                { timeOut: 10000 },
              );
            } else if (it === 1) {
              this.toastService.showWarning("Time expired. Please restart survey.");
              this.restartFormInstance();
            }
          },
        });
    }
  }

  public restartFormInstance() {
    if (this.formGroup) {
      this.formGroup.reset();
    }
    this.currentState = FormCompletionStates.Intro;
    this.submissionUUID = uuid.v4();
    this.showErrors = false;
  }

  public submitFormInstance() {
    this.showErrors = true;
    const formResponse = this.formInstance.collectAnswers(this.formGroup);

    if (!this.formGroup.valid) {
      return;
    }

    this.isSubmitting = true;
    this.formResponseService
      .create({
        ...formResponse,
        submissionUUID: this.submissionUUID,
      })
      .subscribe({
        next: () => {
          this.isSubmitting = false;
          this.formGroup.reset();
          this.currentState = FormCompletionStates.ThankYou;
        },
        error: (error) => {
          this.isSubmitting = false;
          this.toastService.showError(error.error.message);
          Sentry.captureException(error);
        },
      });
  }

  private async _fetchFormInstance() {
    this.formInstanceUUID = this.route.snapshot.paramMap.get("id");

    if (!this.formInstanceUUID) {
      this.router.navigateByUrl("/404", { skipLocationChange: true });
    } else {
      this.isLoadingFormInstance = true;
      try {
        const formInstance = await this.formInstanceService.getFormInstanceByUUID(
          this.formInstanceUUID,
        );
        if (!formInstance.canBeSubmitted) {
          // If the form instance is a placeholder for a CFET, we don't want to let the user fill it out
          this.router.navigateByUrl("/404", { skipLocationChange: true });
          return;
        }

        this._initFormInstance(formInstance);
        if (formInstance.formTemplate.allowCobranding) {
          await this._setSignedLogoUrl();
        }
        this.isLoadingFormInstance = false;
      } catch (error) {
        if (error.statusCode === 404) {
          this.router.navigateByUrl("/404", { skipLocationChange: true });
        } else {
          Sentry.captureException(error);
        }
      }
    }
  }

  private _initFormInstance(formInstance: FormInstance) {
    this.formInstance = formInstance;
    this.formGroup = this.formBuilder.group(this.formInstance.formTemplate.collectFormControls());
  }

  /**
   * Set the signed logo URL for the company if one exists
   */
  private async _setSignedLogoUrl() {
    if (this.formInstance.formGroupInstance.formGroupCompany) {
      await this.formInstance.formGroupInstance.formGroupCompany.setSignedLogoUrl(
        this.formGroupCompanyService,
      );
    }
  }
}
