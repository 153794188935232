export const environment = {
  production: true,
  region: "ap-southeast-2",
  endpoint: "https://api.uat.surveyor.digitalp.com.au",
  logLevel: "debug",
  buildId: process.env.NG_APP_BUILD_ID || "local",
  defaultCollectionLimit: 10,
  enableServiceWorker: true,
  enableSentry: true,
  timeZone: "Australia/Brisbane",
  defaultAuthEmail: "",
  defaultAuthPassword: "",
  defaultDateFormat: "dd MMM yyyy hh:mma",
  defaultShortDateFormat: "dd/MM/yyyy",
};
