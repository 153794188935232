import { FormGroup } from "@angular/forms";
import { Type } from "class-transformer";
import { toCanvas } from "node_modules/bwip-js/dist/bwip-js";
import { environment } from "src/environments/environment";
import { BaseModel } from "../base.model";
import { Benchmark } from "../benchmarking/benchmark.model";
import { FormInstanceType } from "./contract/form-instance-contract";
import { FormAnswer } from "./form-answer.model";
import { FormGroupInstance } from "./form-group-instance.model";
import { FormInstanceContact } from "./form-instance-contact.model";
import { FormResponse, FormResponseEdit } from "./form-response.model";
import { FormTemplateSectionType } from "./form-template-section.model";
import { FormTemplate } from "./form-template.model";

export class FormInstance extends BaseModel {
  service: string;
  id: number;
  uuid: string;
  name: string;
  description: string;
  country: string;
  @Type(() => Boolean)
  hasSMC: boolean;
  @Type(() => Boolean)
  isCFET: boolean;
  @Type(() => Boolean)
  isSA: boolean;
  @Type(() => Boolean)
  isPlaceholder: boolean;
  @Type(() => Date)
  dueAt: Date;
  minResponsesForAbrv: number;
  minResponsesForFull: number;
  @Type(() => Boolean)
  hasSentMinThresholdReachedEmail: boolean;
  @Type(() => Boolean)
  hasSentPerctThresholdReachedEmail: boolean;
  memberId: number;
  @Type(() => Boolean)
  isAvailableOffline: boolean;
  @Type(() => Boolean)
  isDraft: boolean;
  @Type(() => Boolean)
  isArchived: boolean;
  type: FormInstanceType;
  formTemplateId: number;
  @Type(() => FormTemplate)
  formTemplate: FormTemplate;
  @Type(() => FormInstanceContact)
  formInstanceContacts: FormInstanceContact[];
  formGroupInstanceId: number;
  @Type(() => FormGroupInstance)
  formGroupInstance: FormGroupInstance;
  @Type(() => FormResponse)
  formResponses: FormResponse[];
  @Type(() => Benchmark)
  benchmarks: Benchmark[];

  public get url(): URL {
    return new URL(
      `/questionnaire/${this.uuid}`,
      `http${!environment.production ? "" : "s"}://${window.location.host}`,
    );
  }

  hasAnswers(): boolean {
    return this.formResponses && this.formResponses.length > 0;
  }

  get canBeSubmitted(): boolean {
    return !this.isPlaceholder && !this.isArchived;
  }

  get introText() {
    const introductionSection = this.formTemplate.formTemplateSections.find(
      (section) => section.formTemplateSectionType.name === FormTemplateSectionType.Introduction,
    );

    return introductionSection?.value || "";
  }

  get introImage() {
    if (this.formTemplate.image) {
      return `/assets/images/form-template-images/CFEPSurveys_${this.formTemplate.image}.png`;
    }
    return "";
  }

  get subject(): FormInstanceContact | undefined {
    let subject: FormInstanceContact | undefined;

    if (this.formInstanceContacts && this.formInstanceContacts.length) {
      subject = this.formInstanceContacts.find((contact) => contact.isSubject);
    }

    return subject;
  }

  get qrCode() {
    let canvas = document.createElement("canvas");
    toCanvas(canvas, {
      bcid: "qrcode",
      text: this.url.toString(),
      width: 30,
      height: 30,
    });
    return canvas.toDataURL("image/png");
  }

  public collectAnswers(
    formGroup: FormGroup,
    isEdit = false,
  ): Omit<FormResponseEdit, "submissionUUID" | "id"> {
    const answers: FormAnswer[] = [];

    this.formTemplate.questions
      .filter((q) =>
        Object.keys(formGroup.controls).find((controlName) => controlName === q.getName()),
      )
      .forEach((q) => {
        const answer = q.collectAnswer(formGroup, isEdit);
        if (answer) {
          answers.push(...answer);
        }
      });

    return {
      formInstanceId: this.id,
      formAnswers: answers,
    };
  }
}
